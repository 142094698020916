import React, { useState, useEffect } from 'react';
import Header from './Component/Header';
import Sidebar from './Component/Sidebar';
import Content from './Component/Content';
import Footer from './Component/Footer';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../assets/css/Dashboard.css';

const Dashboard = () => {
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const checkSession = async () => {
      try {
        const response = await axios.get('https://app.hagitalconsulting.com/hagitbackend/checksession.php', {
          withCredentials: true, // Add this line to include credentials in the request
        });
        
        console.log('Response from checksession.php:', response.data);
        
        if (response.data.message !== 'Session active') {
          navigate('/admin/AdminLogin');
        }
      } catch (error) {
        console.error('Error checking session:', error);
        setError('An error occurred while checking session');
      }
    };

    checkSession();
  }, [navigate]);

  return (
    <div className="grid-container">
      {error && <p>{error}</p>}
      <Header />
      <Sidebar />
      <Content />
      <Footer />
    </div>
  );
};

export default Dashboard;
