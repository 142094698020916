import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Welcome from './welcome';
import Hagiters from './proof/hagiters';
import App from './App';
import AdminApp from './admin/App'; 
import AdminLogin from './admin/AdminLogin';
import AdminDashboard from './admin/Dashboard';
import reportWebVitals from './reportWebVitals';


import UserDashboard from './user/Dashboard';




import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/hagiters" element={<Hagiters />} />
        <Route path="/admin/*" element={<AdminApp />} />
        <Route path="/admin/AdminLogin" element={<AdminLogin />} />
        <Route path="/admin/Dashboard" element={<AdminDashboard />} />
        <Route path="/user/Dashboard" element={<UserDashboard />} />

      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
