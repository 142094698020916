// Footer.js
import React from 'react';

const UserFooter = () => {
  return (
    <footer className="footer">
      {/* Your footer content goes here */}
      <p>&copy; 2023 Hagit by Hagitals Consulting</p>
    </footer>
  );
};

export default UserFooter;
