import React, { useState, useEffect } from 'react';
import Header from '../Header';
import Sidebar from '../Sidebar';
import Footer from '../Footer';

const AddCourses = () => {
  const [selectedItem, setSelectedItem] = useState({});
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);

  useEffect(() => {
    // Fetch data from the PHP API endpoint for courses, categories, and subcategories
    fetchData('http://localhost/hagitbackend/course.php');
  }, []);

  const fetchData = (url) => {
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setCategories(data.categories || []); // Ensure categories is an array
        setSubcategories(data.subcategories || []); // Ensure subcategories is an array
      })
      .catch((error) => console.error('Error fetching data:', error));
  };

  const handleInputChange = (key, value) => {
    setSelectedItem((prevItem) => ({ ...prevItem, [key]: value }));
  };

  const handleAdd = () => {
    // Add a new course to the backend/database using the PHP API
    fetch('http://localhost/hagitbackend/course.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(selectedItem),
    })
      .then((response) => response.json())
      .then((result) => {
        console.log('Add result:', result);
        // If the addition was successful, refresh the data
        if (result.success) {
          fetchData('http://localhost/hagitbackend/course.php');
          setSelectedItem({});
        }
      })
      .catch((error) => console.error('Error adding data:', error));
  };


  return (
    <div className="grid-container">
    <Header />
    <Sidebar />
    <main className="main-container">
      <h3> Add New Course</h3>
      <form>
        <div className="form-group">
          <label className="form-label-hagit">Course Name</label>
          <input
            type="text"
            value={selectedItem.course_name || ''}
            onChange={(e) => handleInputChange('course_name', e.target.value)}
            className="form-control"
          />
        </div>

        {/* Other form fields */}

        {/* Category Dropdown */}
        <div className="form-group">
          <label className="form-label-hagit">Category</label>
          <select
            value={selectedItem.category || ''}
            onChange={(e) => handleInputChange('category', e.target.value)}
            className="form-control"
          >
            <option value="">Select Category</option>
            {categories.map((category, index) => (
              <option key={index} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>

        {/* Subcategory Dropdown */}
        <div className="form-group">
          <label className="form-label-hagit">Subcategory</label>
          <select
            value={selectedItem.subcategory || ''}
            onChange={(e) => handleInputChange('subcategory', e.target.value)}
            className="form-control"
          >
            <option value="">Select Subcategory</option>
            {subcategories.map((subcategory, index) => (
              <option key={index} value={subcategory}>
                {subcategory}
              </option>
            ))}
          </select>
        </div>

          <div className="row mt-3">
            <div className="col-md-12">
              {/* Render buttons for Add and Reset */}
              <div className="action-buttons-hagit d-flex justify-content-between">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleAdd}
                >
                  Add
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setSelectedItem({})}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </form>
      </main>
      <Footer />
    </div>
  );
};

export default AddCourses;
