import React, { useState } from 'react';
import './hagiters.css';
import axios from 'axios';

import hagital from "./hagiter.jpg";

const Form = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    whatsapp: '',
    courses: [], // Change to an array to store multiple courses
    payment: null,
    nameOnAccount: '',
  });

  const [submissionStatus, setSubmissionStatus] = useState({
    message: '',
    isSuccess: false,
  });

  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value, files, checked } = e.target;

    if (name === 'courses') {
      // Handle changes in checkbox inputs for courses
      setFormData((prevData) => {
        const updatedCourses = checked
          ? [...prevData.courses, value]
          : prevData.courses.filter((course) => course !== value);

        return {
          ...prevData,
          courses: updatedCourses.slice(0, 3), // Limit to 3 courses
        };
      });
    } else {
      // Handle changes in other input fields
      setFormData((prevData) => ({
        ...prevData,
        [name]: name === 'payment' ? files[0] : value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    if (
      !formData.name ||
      !formData.email ||
      !formData.whatsapp ||
      !formData.courses.length || // Check the length of courses array
      !formData.payment ||
      !formData.nameOnAccount
    ) {
      setSubmissionStatus({
        message: 'Please fill in all fields and upload payment proof.',
        isSuccess: false,
      });
      return;
    }

    const data = new FormData();
    data.append('name', formData.name);
    data.append('email', formData.email);
    data.append('whatsapp', formData.whatsapp);
    data.append('courses', formData.courses.join(',')); // Join courses array into a comma-separated string
    data.append('payment', formData.payment);
    data.append('nameOnAccount', formData.nameOnAccount);

    axios
      .post('https://app.hagitalconsulting.com/hagitbackend/api.php', data)
      .then((response) => {
        setSubmissionStatus({
          message:
            'Details submitted successfully. You will receive a response via email, SMS, or WhatsApp.',
          isSuccess: true,
        });
        setShowPopup(true); // Show the success popup
      })
      .catch((error) => {
        setSubmissionStatus({
          message: 'Error submitting details. Please try again.',
          isSuccess: false,
        });
        console.error('Error submitting form:', error);
      });
  };

  const handlePopupOk = () => {
    setShowPopup(false); // Close the popup
    window.location.href = 'https://app.hagitalconsulting.com/'; // Redirect the user
  };

  return (
    <div className="form-container">
      <div className="left-side">
        {/* Big Image on the Left */}
        <img src={hagital} alt="hagiter" className="hagiter" />
      </div>
      <div className="right-side">
        {/* Form on the Right */}
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          {loading && (
            <div className="loader-container">
              <div className="loader"></div>
            </div>
          )}
          {submissionStatus.isSuccess && (
            <div className="submission-success">{submissionStatus.message}</div>
          )}
          {!submissionStatus.isSuccess && submissionStatus.message && (
            <div className="submission-error">{submissionStatus.message}</div>
          )}

          <h4>Please complete the form and submit your payment proof.</h4>
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" name="name" onChange={handleChange} placeholder="Enter your name" />

          <label htmlFor="email">Email:</label>
          <input type="email" id="email" name="email" onChange={handleChange} placeholder="Enter your email" />

          <label htmlFor="whatsapp">WhatsApp Number:</label>
          <input type="tel" id="whatsapp" name="whatsapp" onChange={handleChange} placeholder="Enter your WhatsApp number" />

          <label htmlFor="courses">Preferred Courses</label>
          <div className="checkbox-options">
            <label>
              <input
                type="checkbox"
                name="courses"
                value="Product Design"
                checked={formData.courses.includes("Product Design")}
                onChange={handleChange}
              />
              Product Design
            </label>

            <label>
              <input
                type="checkbox"
                name="courses"
                value="Software Testing"
                checked={formData.courses.includes("Software Testing")}
                onChange={handleChange}
              />
              Software Testing
            </label>

            <label>
              <input
                type="checkbox"
                name="courses"
                value="Data Science"
                checked={formData.courses.includes("Data Science")}
                onChange={handleChange}
              />
              Data Science
            </label>

            <label>
              <input
                type="checkbox"
                name="courses"
                value="Data Analytics"
                checked={formData.courses.includes("Data Analytics")}
                onChange={handleChange}
              />
              Data Analytics
            </label>

            <label>
              <input
                type="checkbox"
                name="courses"
                value="Business Analysis and Scrum"
                checked={formData.courses.includes("Business Analysis and Scrum")}
                onChange={handleChange}
              />
              Business Analysis & Scrum
            </label>

            <label>
              <input
                type="checkbox"
                name="courses"
                value="Cloud Solutions Architects"
                checked={formData.courses.includes("Cloud Solutions Architects")}
                onChange={handleChange}
              />
              Cloud Solutions Architects
            </label>

            <label>
              <input
                type="checkbox"
                name="courses"
                value="Cyber Security"
                checked={formData.courses.includes("Cyber Security")}
                onChange={handleChange}
              />
              Cyber Security
            </label>

            <label>
              <input
                type="checkbox"
                name="courses"
                value="Cyber Security Non-Technical"
                checked={formData.courses.includes("Cyber Security Non-Technical")}
                onChange={handleChange}
              />
              Cyber Security Non-Technical
            </label>

            <label>
              <input
                type="checkbox"
                name="courses"
                value="Digital Marketing"
                checked={formData.courses.includes("Digital Marketing")}
                onChange={handleChange}
              />
              Digital Marketing
            </label>

            <label>
              <input
                type="checkbox"
                name="courses"
                value="SEO"
                checked={formData.courses.includes("SEO")}
                onChange={handleChange}
              />
              SEO
            </label>
          </div>

          <label htmlFor="payment">Upload Payment:</label>
          <input type="file" id="payment" name="payment" onChange={handleChange} accept="image/*" />

          <label htmlFor="nameOnAccount">Name on Account:</label>
          <input type="text" id="nameOnAccount" name="nameOnAccount" onChange={handleChange} placeholder="Enter the name on your account" />

          {/* Terms and Conditions */}
          <button type="submit" disabled={loading}>
            {loading ? 'Uploading...' : 'Upload Payment'}
          </button>
        </form>

        {/* Popup */}
        {showPopup && (
          <div className="popup">
            <div className="popup-content">
              <span className="close-popup" onClick={handlePopupOk}>
                &times;
              </span>
              <p>{submissionStatus.message}</p>
              <button className="popup-ok-button" onClick={handlePopupOk}>
                OK
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Form;
