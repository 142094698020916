import React, { useState, useEffect } from 'react';
import Header from '../Header';
import Sidebar from '../Sidebar';
import Footer from '../Footer';

const AddFacilitator = () => {
  const [selectedItem, setSelectedItem] = useState({});
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    // Fetch data from the PHP API endpoint for facilitators
    fetchData('http://localhost/hagitbackend/facilitator.php');
  }, []);

  const fetchData = (url) => {
    fetch(url, {
      method: 'OPTIONS',
    })
      .then((response) => response.json())
      .then((options) => {
        // Note: No need to set categories and subcategories in this case
      })
      .catch((error) => console.error('Error fetching options:', error));
  };

  const handleInputChange = (key, value) => {
    setSelectedItem((prevItem) => ({ ...prevItem, [key]: value }));
  };

  const handleAdd = () => {
    // Add a new facilitator to the backend/database using the PHP API
    fetch('http://localhost/hagitbackend/facilitator.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(selectedItem),
    })
      .then((response) => response.json())
      .then((result) => {
        console.log('Add result:', result);
        if (result.success) {
          setSuccessMessage(result.message);
          setSelectedItem({});
        } else {
          setSuccessMessage(result.message);
        }
      })
      .catch((error) => {
        console.error('Error during fetch:', error);
        setSuccessMessage('Error adding facilitator. Please try again.');
      });
    
  };

  useEffect(() => {
    console.log('Success message:', successMessage);
  }, [successMessage]);
  
  

  return (
    <div className="grid-container">
      <Header />
      <Sidebar />
      <main className="main-container">
        <h3> Add New Facilitator</h3>
       
        <form>
        {successMessage && <p className="text-success">{successMessage}</p>}
          <div className="form-group">
            <label className="form-label-hagit">Name</label>
            <input
              type="text"
              value={selectedItem.name || ''}
              onChange={(e) => handleInputChange('name', e.target.value)}
              className="form-control"
            />
          </div>

          <div className="form-group">
            <label className="form-label-hagit">Email Address</label>
            <input
              type="email"
              value={selectedItem.email || ''}
              onChange={(e) => handleInputChange('email', e.target.value)}
              className="form-control"
            />
          </div>

          <div className="form-group">
            <label className="form-label-hagit">Phone Number</label>
            <input
              type="tel"
              value={selectedItem.phone_number || ''}
              onChange={(e) => handleInputChange('phone_number', e.target.value)}
              className="form-control"
            />
          </div>

          <div className="form-group">
            <label className="form-label-hagit">Specialization</label>
            <input
              type="text"
              value={selectedItem.specialization || ''}
              onChange={(e) => handleInputChange('specialization', e.target.value)}
              className="form-control"
            />
          </div>

          <div className="form-group">
            <label className="form-label-hagit">Course Taught</label>
            <input
              type="text"
              value={selectedItem.course_taught || ''}
              onChange={(e) => handleInputChange('course_taught', e.target.value)}
              className="form-control"
            />
          </div>

          <div className="row mt-3">
            <div className="col-md-12">
              {/* Render buttons for Add and Reset */}
              <div className="action-buttons-hagit d-flex justify-content-between">
              <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleAdd}
                >
                  Add
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    setSelectedItem({});
                    setSuccessMessage('');
                  }}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </form>
      </main>
      <Footer />
    </div>
  );
};

export default AddFacilitator;
