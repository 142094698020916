import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  BsGrid1X2Fill,
  BsFillGrid3X3GapFill,
  BsPeopleFill,
  BsFillGearFill,
  BsBookFill,
  BsPersonVcardFill,
  BsPieChartFill,
  BsFillCaretDownFill,
} from 'react-icons/bs';
import '../../assets/css/Dashboard.css';

import logo from '../../assets/logo.svg';


const DropdownMenu = ({ isOpen, items, onItemClick }) => {
  return (
    <div className={`dropdown-container ${isOpen ? 'active' : ''}`}>
      {items.map((item, index) => (
        <Link
          key={index}
          to={`/admin${item.link}`}
          className='dropdown-item'
          onClick={() => onItemClick(item)}
        >
          {item.label}
        </Link>
      ))}
    </div>
  );
};

const Sidebar = () => {
  const [activeMenu, setActiveMenu] = useState(null);
  

  const [dropdownState, setDropdownState] = useState({
    students: false,
    courses: false,
    facilitators: false,
    staff: false,
    reports: false,
  });

  const toggleDropdown = (menu) => {
    setDropdownState((prevState) => ({
      ...prevState,
      [menu]: !prevState[menu],
    }));
  };

  const handleMenuClick = (menu) => {
    setActiveMenu((prevActiveMenu) => (prevActiveMenu === menu ? null : menu));
  };

  const handleDropdownItemClick = (menu) => {
    setActiveMenu((prevActiveMenu) => (prevActiveMenu === menu ? null : menu));
  };

  const dropdownItems = {
    students: [
      { label: 'Enrollment', link: '/Component/Students/Enrollment' },
      { label: 'Student Enrolled', link: '/Component/Students/Enrolled' },
      // Add more student-related dropdown items as needed
    ],
    courses: [
      { label: 'Add New Course', link: '/Component/Courses/AddCourses' },
      { label: 'View All Courses', link: '/Component/Courses/ViewCourses' },
      // Add more course-related dropdown items as needed
    ],
    facilitators: [
      { label: 'Add New Facilitator', link: '/Component/Facilitator/AddFacilitator' },
      { label: 'View Facilitator', link: '/Component/Facilitator/ViewFacilitator' },
      // Add more facilitator-related dropdown items as needed
    ],
    staff: [
      { label: 'Add a Staff', link: '/Component/Staff/AddStaff' },
      { label: 'Assign a Staff', link: '/Component/Staff/AssignStaff' },
      { label: 'View all', link: '/Component/Staff/ViewStaff' },
      // Add more staff-related dropdown items as needed
    ],
    reports: [
      { label: 'Report 1', link: '/reports/report1' },
      { label: 'Report 2', link: '/reports/report2' },
      // Add more report-related dropdown items as needed
    ],
  };
  
  return (
    <aside id='sidebar'>
      <div className='sidebar-title'>
        <div className='sidebar-brand'>
          <img src={logo} alt="Logo" className="icon_header" />
        </div>
        <span className='icon close_icon'>X</span>
      </div>

      <ul className='sidebar-list'>
        <li className='sidebar-list-item'>
        <Link to="/admin/Dashboard">
            <BsGrid1X2Fill className='icon' /> Dashboard
        </Link>
        </li>
        <li className={`sidebar-list-item ${activeMenu === 'students' ? 'active' : ''}`}>
          <div onClick={() => handleMenuClick('students')} className="dropdown-btn">
            <BsPeopleFill className="icon" /> Students
            <BsFillCaretDownFill className={`icon caret-icon ${dropdownState.students ? 'active' : ''}`} onClick={() => toggleDropdown('students')} />
          </div>
          <DropdownMenu
            isOpen={dropdownState.students}
            items={dropdownItems.students}
            onItemClick={handleDropdownItemClick}
          />
        </li>
        <li className={`sidebar-list-item ${activeMenu === 'courses' ? 'active' : ''}`}>
          <div onClick={() => handleMenuClick('courses')} className="dropdown-btn">
            <BsBookFill className="icon" /> Course
            <BsFillCaretDownFill className={`icon caret-icon ${dropdownState.students ? 'active' : ''}`} onClick={() => toggleDropdown('courses')} />
          </div>
          <DropdownMenu
            isOpen={dropdownState.courses}
            items={dropdownItems.courses}
            onItemClick={handleDropdownItemClick}
          />
        </li>
        <li className='sidebar-list-item'>
          <Link>
            <BsFillGrid3X3GapFill className='icon' /> Curriculum
          </Link>
        </li>
        <li className={`sidebar-list-item ${activeMenu === 'courses' ? 'active' : ''}`}>
          <div onClick={() => handleMenuClick('facilitators')} className="dropdown-btn">
            <BsPeopleFill className="icon" /> Facilitator
            <BsFillCaretDownFill className={`icon caret-icon ${dropdownState.students ? 'active' : ''}`} onClick={() => toggleDropdown('facilitators')} />
          </div>
          <DropdownMenu
            isOpen={dropdownState.facilitators}
            items={dropdownItems.facilitators}
            onItemClick={handleDropdownItemClick}
          />
        </li>
        <li className={`sidebar-list-item ${activeMenu === 'staff' ? 'active' : ''}`}>
          <div onClick={() => handleMenuClick('staff')} className="dropdown-btn">
            <BsPersonVcardFill className="icon" /> Staff
            <BsFillCaretDownFill className={`icon caret-icon ${dropdownState.staff ? 'active' : ''}`} onClick={() => toggleDropdown('staff')} />
          </div>
          <DropdownMenu
            isOpen={dropdownState.staff}
            items={dropdownItems.staff}
            onItemClick={handleDropdownItemClick}
          />
        </li>
        <li className={`sidebar-list-item ${activeMenu === 'reports' ? 'active' : ''}`}>
          <div onClick={() => handleMenuClick('reports')} className="dropdown-btn">
            <BsPieChartFill className="icon" /> Reports
            <BsFillCaretDownFill className={`icon caret-icon ${dropdownState.reports ? 'active' : ''}`} onClick={() => toggleDropdown('report')} />
          </div>
          <DropdownMenu
            isOpen={dropdownState.reports}
            items={dropdownItems.reports}
            onItemClick={handleDropdownItemClick}
          />
        </li>
        <li className='sidebar-list-item'>
          <Link>
            <BsFillGearFill className='icon' /> Settings
          </Link>
        </li>
      </ul>
    </aside>
  );
};

export default Sidebar;
