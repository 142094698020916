import React, { useState, useEffect } from 'react';
import Header from '../Header';
import Sidebar from '../Sidebar'; 
import Footer from '../Footer';
import '../../../assets/css/Dashboard.css';
import '../../../assets/css/students.css';
import { BsFilter } from 'react-icons/bs';
import { CSVLink } from 'react-csv';
import * as XLSX from 'xlsx';



const Enrolled = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);

  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [csvData, setCsvData] = useState([]);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    // Fetch data from the PHP API endpoint
    fetch('https://app.hagitalconsulting.com/hagitbackend/HagitUser.php', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Add logic to handle paymentStatus and remainingAmount
        const modifiedData = data.map((item) => ({
          ...item,
          paymentStatus: item.paymentStatus === 1 ? 'Full Payment' : 'Partial Payment',
          remainingAmount: item.remainingAmount || 0,
        }));

        setData(modifiedData);
        setFilteredData(modifiedData);
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);

    const filteredResults = data.filter((item) =>
        expectedKeys.some((key) =>
          key === 'courses'
            ? item[key].toLowerCase().includes(term)
            : item[key].toString().toLowerCase().includes(term)
        )
      );

      setFilteredData(filteredResults);
    };

  const expectedKeys = [
    'id',
    'name',
    'email',
    'whatsapp',
    'hagit_pin',
    'courses',
    'payment_status',
    'remaining_amount',
  ];

  const handleViewDetails = (item) => {
    setSelectedItem(item);
  };

  const handleProfilePhotoChange = (e) => {
    // Implement logic to handle profile photo change
  };
  
  const handleInputChange = (key, value) => {
    // Implement logic to update the state as the user edits input fields
    // You may need to use setSelectedItem to update the state
  };
  
  const handleUpdate = () => {
    // Implement logic to update the data in your backend/database
  };
  
  const handleDelete = () => {
    // Implement logic to delete the data in your backend/database
  };

  const generateCsvData = () => {
    const formattedCsvData = filteredData.map((item) => ({
      name: item.name,
      email: item.email,
      whatsapp: item.whatsapp,
      courses: item.courses,
      payment_path: item.payment_path,
      name_on_account: item.name_on_account,
    }));
    setCsvData(formattedCsvData);
  };

  const handleDownload = (format) => {
    if (format === 'csv') {
      generateCsvData();
    } else if (format === 'excel') {
      const ws = XLSX.utils.json_to_sheet(filteredData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'enrollment_data.xlsx');
    } else if (format === 'print') {
      window.print();
    }
  };
  

  return (
    <div className="grid-container">
      <Header />
      <Sidebar />
      <main className="main-container">
        <h3> Approved Students</h3>
        <div className="search-box">
          <BsFilter className="filter-icon" />
          <input
            type="text"
            placeholder="Search by name or phone number"
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
        <div className="download-buttons">
          <CSVLink
            data={csvData}
            filename="enrollment_data.csv"
            onClick={() => generateCsvData()}
            className="download-btn csv-button"
          >
            CSV
          </CSVLink>
          <button
            onClick={() => handleDownload('excel')}
            className="download-btn"
          >
            Excel
          </button>
          <button
            onClick={() => handleDownload('print')}
            className="download-btn print-btn"
          >
            Print
          </button>
        </div>
        <div className="printable-table">
        <table className="data-table">
          <thead>
            <tr>
              {expectedKeys.map((key, index) => (
                <th key={index}>
                  {key === 'id' && 'S/N'}
                  {key === 'name' && 'Full name'}
                  {key === 'email' && 'Email Address'}
                  {key === 'whatsapp' && 'WhatsApp Number'}
                  {key === 'hagit_pin' && 'Hagit Pin'}
                  {key === 'courses' && 'Preffered Course'}
                  {key === 'payment_status' && 'Payment Status'}
                  {key === 'remaining_amount' && 'To Balance'}
                </th>
              ))}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentData.map((item, index) => (
              <tr key={index}>
                {expectedKeys.map((key, subIndex) => (
                  <td key={subIndex}>{item[key]}</td>
                ))}
                <td>
                  <button
                    className="view-btn"
                    onClick={() => handleViewDetails(item)}
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Pagination */}
        <div className="pagination">
        {Array.from({ length: Math.ceil(filteredData.length / itemsPerPage) }).map((_, index) => (
            <button key={index} onClick={() => paginate(index + 1)}>
              {index + 1}
            </button>
          ))}
        </div>
        </div>

        {selectedItem && (
          <div className="popup-hagit">
            <div className="popup-content-hagit container">
              <span className="close-popup-hagit" onClick={() => setSelectedItem(null)}>
                &times;
              </span>
              <h2 className="details-heading-hagit">Profile Details</h2>
              <form>
                <div className="row">
                  <div className="col-md-4">
                    <div className="profile-photo-section-hagit text-center">
                      <label className="profile-photo-label-hagit">Profile Photo</label>
                      <img
                        src={selectedItem.profilePhoto}
                        alt="Profile-hagit"
                        className="profile-photo-hagit img-fluid rounded-circle"
                      />
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleProfilePhotoChange}
                        className="form-control mt-2"
                      />
                    </div>
                  </div>
                  <div className="col-md-8">
                    {expectedKeys.map((key, index) => (
                      <div key={index} className="form-group">
                        <label className="form-label-hagit">
                          {key}
                        </label>
                        <input
                          type="text"
                          value={selectedItem[key]}
                          onChange={(e) => handleInputChange(key, e.target.value)}
                          className="form-control"
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-12">
                    <div className="action-buttons-hagit d-flex justify-content-between">
                      <button type="button" className="btn btn-success" onClick={handleUpdate}>
                        Update
                      </button>
                      <button type="button" className="btn btn-danger" onClick={handleDelete}>
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}


      </main>
      <Footer />
    </div>
  );
};

export default Enrolled;