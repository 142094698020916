import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import AdminLogin from './AdminLogin';
import AdminDashboard from './Dashboard';

import Header from './Component/Header';
import SideBar from './Component/Sidebar';
import Content from './Component/Content';

import Enrollment from './Component/Students/Enrollment';
import Enrolled from './Component/Students/Enrolled';
import AddCourses from './Component/Courses/AddCourses';
import ViewCourses from './Component/Courses/ViewCourses';
import AddFacilitator from './Component/Facilitator/AddFacilitator';
import ViewFacilitator from './Component/Facilitator/ViewFacilitator';
import AddStaff from './Component/Staff/AddStaff';
import ViewStaff from './Component/Staff/ViewStaff';
import AssignStaff from './Component/Staff/AssignStaff';


const AdminApp = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<Navigate to="/admin/AdminLogin" replace />}
      />

      <Route path="/admin/AdminLogin" element={<AdminLogin />} />
      <Route path="/admin/Dashboard" element={<AdminDashboard />} />
      <Route path="/Component/Header" element={<Header />} />
      <Route path="/Component/Sidebar" element={<SideBar />} />
      <Route path="/Component/Content" element={<Content />} />
      <Route path="/Component/Students/Enrollment" element={<Enrollment />} />
      <Route path="/Component/Students/Enrolled" element={<Enrolled />} />
      <Route path="/Component/Courses/AddCourses" element={<AddCourses />} />
      <Route path="/Component/Courses/ViewCourses" element={<ViewCourses />} />
      <Route path="/Component/Facilitator/AddFacilitator" element={<AddFacilitator />} />
      <Route path="/Component/Facilitator/ViewFacilitator" element={<ViewFacilitator />} />
      <Route path="/Component/Staff/AddStaff" element={<AddStaff />} />
      <Route path="/Component/Staff/ViewStaff" element={<ViewStaff />} />
      <Route path="/Component/Staff/AssignStaff" element={<AssignStaff />} />
    </Routes>
  );
};

export default AdminApp;
