// CertificatePage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const CertificatePage = () => {
  const navigate = useNavigate();
  const [fullName, setFullName] = useState('');

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // You can add logic to handle form submission (e.g., send data to the server)
    // For now, let's simulate a successful submission and navigate to the confirmation page
    navigate('/certificate-confirmation');
  };

  return (
    <div className="certificate-page">
      <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Enter Your Full Name</h2>
      <form onSubmit={handleFormSubmit} style={{ maxWidth: '400px', margin: '0 auto' }}>
        <label htmlFor="fullName" style={{ display: 'block', marginBottom: '10px' }}>
          Full Name:
        </label>
        <input
          type="text"
          id="fullName"
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
          required
          style={{ width: '100%', padding: '8px', marginBottom: '20px' }}
        />
        <button
          type="submit"
          style={{
            backgroundColor: '#4caf50',
            color: 'white',
            padding: '10px 15px',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
        >
          Generate Certificate
        </button>
      </form>
    </div>
  );
};

export default CertificatePage;
