import React from 'react';

const AssignStaff = () => {
  return (
    <div>
      <h1>Students Enrolled Component</h1>
      {/* Add your content here */}
    </div>
  );
};

export default AssignStaff;
