import React, { useState, useEffect } from 'react';
import Header from '../Header';
import Sidebar from '../Sidebar';
import Footer from '../Footer';

const AddStaff = () => {
  const [selectedItem, setSelectedItem] = useState({});
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    // Fetch data from the PHP API endpoint for staff
    fetchData('http://localhost/hagitbackend/staff.php');
  }, []);

  const fetchData = (url) => {
    fetch(url, {
      method: 'OPTIONS',
    })
      .then((response) => response.json())
      .then((options) => {
        // Note: No need to set categories and subcategories in this case
      })
      .catch((error) => console.error('Error fetching options:', error));
  };

  const handleInputChange = (key, value) => {
    setSelectedItem((prevItem) => ({ ...prevItem, [key]: value }));
  };

  const handleAdd = () => {
    // Add a new staff member to the backend/database using the PHP API
    fetch('http://localhost/hagitbackend/staff.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        staff_name: selectedItem.staff_name,
        email_address: selectedItem.email_address,
        phone_number: selectedItem.phone_number,
        job_description: selectedItem.job_description,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        console.log('Add result:', result);
        if (result.success) {
          setSuccessMessage(result.message);
          setSelectedItem({});
        } else {
          setSuccessMessage(result.message);
        }
      })
      .catch((error) => {
        console.error('Error during fetch:', error);
        setSuccessMessage('Error adding staff member. Please try again.');
      });
  };
    
  useEffect(() => {
    console.log('Success message:', successMessage);
  }, [successMessage]);
  
  

  return (
    <div className="grid-container">
      <Header />
      <Sidebar />
      <main className="main-container">
        <h3> Add New Staff</h3>
       
        <form>
        {successMessage && <p className="text-success">{successMessage}</p>}
          <div className="form-group">
            <label className="form-label-hagit">Staff Name</label>
            <input
              type="text"
              value={selectedItem.staff_name || ''}
              onChange={(e) => handleInputChange('staff_name', e.target.value)}
              className="form-control"
            />
          </div>

          <div className="form-group">
            <label className="form-label-hagit">Email Address</label>
            <input
              type="email"
              value={selectedItem.email_address || ''}
              onChange={(e) => handleInputChange('email_address', e.target.value)}
              className="form-control"
            />
          </div>

          <div className="form-group">
            <label className="form-label-hagit">Phone Number</label>
            <input
              type="tel"
              value={selectedItem.phone_number || ''}
              onChange={(e) => handleInputChange('phone_number', e.target.value)}
              className="form-control"
            />
          </div>

          <div className="form-group">
            <label className="form-label-hagit">Job Description</label>
            <input
              type="text"
              value={selectedItem.job_description || ''}
              onChange={(e) => handleInputChange('job_description', e.target.value)}
              className="form-control"
            />
          </div>

          <div className="row mt-3">
            <div className="col-md-12">
              {/* Render buttons for Add and Reset */}
              <div className="action-buttons-hagit d-flex justify-content-between">
              <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleAdd}
                >
                  Add
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    setSelectedItem({});
                    setSuccessMessage('');
                  }}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </form>
      </main>
      <Footer />
    </div>
  );
};

export default AddStaff;
