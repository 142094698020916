import React, { useState, useEffect } from 'react';
import Header from '../Header';
import Sidebar from '../Sidebar';
import Footer from '../Footer';
import { BsFilter } from 'react-icons/bs';

const ViewFacilitator = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const [updateSuccessMessage, setUpdateSuccessMessage] = useState('');
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState('');


  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    // Fetch facilitators from the PHP API endpoint
    fetch('http://localhost/hagitbackend/facilitator.php')
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        setFilteredData(data);
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);

    const filteredResults = data.filter((item) =>
      expectedKeys.some((key) =>
        item[key].toString().toLowerCase().includes(term)
      )
    );

    setFilteredData(filteredResults);
  };

  const expectedKeys = ['name', 'email', 'phone_number', 'specialization', 'course_taught'];

  const handleEdit = (item) => {
    setSelectedItem(item);
  };

  const handleDelete = (item) => {
    // Update the data in your backend/database using the PHP API
    fetch(`http://localhost/hagitbackend/facilitator.php?id=${item.id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    })
    .then((response) => response.json())
    .then((result) => {
        console.log('Delete result:', result);
        // Remove the item from the local state if the deletion was successful
        if (result.success) {
            setData((prevData) =>
                prevData.filter((facilitator) => facilitator.id !== item.id)
            );
            setFilteredData((prevFilteredData) =>
                prevFilteredData.filter((facilitator) => facilitator.id !== item.id)
            );
            setDeleteSuccessMessage('Facilitator deleted successfully'); // Set the success message
        }
    })
    .catch((error) => {
        console.error('Error deleting data:', error);
        setDeleteSuccessMessage('Error deleting facilitator. Please try again.'); // Set error message
    });
}; 

const handleInputChange = (key, value) => {
    setSelectedItem((prevItem) => ({ ...prevItem, [key]: value }));
  };

  const handleUpdate = () => {
    console.log('Updating facilitator:', selectedItem);
  
    // Update the data in your backend/database using the PHP API
    fetch(`http://localhost/hagitbackend/facilitator.php?id=${selectedItem.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(selectedItem),
    })
      .then((response) => response.json())
      .then((result) => {
        console.log('Update result:', result);
        // Update the local state if the update was successful
        if (result.success) {
          setData((prevData) =>
            prevData.map((facilitator) =>
              facilitator.id === selectedItem.id ? selectedItem : facilitator
            )
          );
          setFilteredData((prevFilteredData) =>
            prevFilteredData.map((facilitator) =>
              facilitator.id === selectedItem.id ? selectedItem : facilitator
            )
          );
          setSelectedItem(null);
          setUpdateSuccessMessage('Facilitator updated successfully'); // Set the success message
        }
      })
      .catch((error) => {
        console.error('Error updating data:', error);
        setUpdateSuccessMessage('Error updating facilitator. Please try again.'); // Set error message
      });
  };
  
  

  return (
    <div className="grid-container">
      <Header />
      <Sidebar />
      <main className="main-container">
        <h3> List of Facilitators</h3>
        <div className="search-box">
          <BsFilter className="filter-icon" />
          <input
            type="text"
            placeholder="Search by name or phone number"
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
        <table className="data-table">
          <thead>
            <tr>
              {expectedKeys.map((key, index) => (
                <th key={index}>
                  {key === 'id' && 'S/N'}
                  {key === 'name' && 'Name'}
                  {key === 'email' && 'Email Address'}
                  {key === 'phone_number' && 'Phone Number'}
                  {key === 'specialization' && 'Specialization'}
                  {key === 'course_taught' && 'Course'}
                </th>
              ))}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentData.map((item, index) => (
              <tr key={index}>
                {expectedKeys.map((key, subIndex) => (
                  <td key={subIndex}>{item[key]}</td>
                ))}
                <td>
                  <button
                    className="edit-btn custom-btn btn btn-success btn-sm custom-btn"
                    onClick={() => handleEdit(item)}
                  >
                    Edit
                  </button>
                  <button
                    className="delete-btn custom-btn btn btn-danger btn-sm custom-btn"
                    onClick={() => handleDelete(item)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Pagination */}
        <div className="pagination">
          {Array.from({ length: Math.ceil(filteredData.length / itemsPerPage) }).map((_, index) => (
            <button key={index} onClick={() => paginate(index + 1)}>
              {index + 1}
            </button>
          ))}
        </div>

        {selectedItem && (
          <div className="popup-hagit">
            <div className="popup-content-hagit">
              <span className="close-popup-hagit" onClick={() => setSelectedItem(null)}>
                &times;
              </span>
              {updateSuccessMessage && (
                <p className="text-success">{updateSuccessMessage}</p>
              )}
              {deleteSuccessMessage && (
                <p className="text-success">{deleteSuccessMessage}</p>
              )}
              <h2 className="details-heading-hagit">Profile Details</h2>
              <form>
                <div className="row">
                  <div className="col-md-4">
                    <div className="profile-photo-section-hagit text-center">
                      <label className="profile-photo-label-hagit">Profile Photo</label>
                    </div>
                  </div>
                  <div className="col-md-8">
                    {expectedKeys.map((key, index) => (
                      <div key={index} className="form-group">
                        <label className="form-label-hagit">
                          {key}
                        </label>
                        <input
                          type="text"
                          value={selectedItem[key]}
                          onChange={(e) => handleInputChange(key, e.target.value)}
                          className="form-control"
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-12">
                    <div className="action-buttons-hagit d-flex justify-content-between">
                      <button type="button" className="btn btn-success btn-sm custom-btn" onClick={handleUpdate}>
                        Update
                      </button>
                      <button type="button" className="btn btn-danger btn-sm custom-btn" onClick={handleDelete}>
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </main>
      <Footer />
    </div>
  );
};

export default ViewFacilitator;
