import React from 'react';

const CertificateConfirmationPage = () => {
  return (
    <div className="certificate-confirmation-page" style={{ textAlign: 'center' }}>
      <h2>Congratulations!</h2>
      <p>Your certificate has been generated successfully.</p>
      {/* Add any additional information or links as needed */}
    </div>
  );
};

export default CertificateConfirmationPage;
