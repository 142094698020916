import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo.svg';

const AdminLogin = () => {
    const [formData, setFormData] = useState({
        username: '',
        password: '',
    });

    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    };

// Inside the AdminLogin component
const handleLogin = async () => {
  try {
      const response = await axios.post(
          'https://app.hagitalconsulting.com/hagitbackend/adminapi.php',
          {
              login: true,
              username: formData.username,
              password: formData.password,
          },
          {
              withCredentials: true,
          }
      );

      if (response.data.message === 'Admin login successful') {
          navigate('/admin/Dashboard');
      } else {
          setError('Invalid username or password');
      }
  } catch (error) {
      console.error('Error during login:', error);
      const errorMessage = error.response
          ? error.response.data.message
          : 'An error occurred during login. Please try again later.';
      setError(errorMessage);
  }
};





    return (
        <div>
               
            <form>
            <img src={logo} alt="hagit" className="logoAdmin" />
               {error && <h1>{error}</h1>}
                <label htmlFor="username">Username:</label>
                <input
                    type="text"
                    onChange={handleChange}
                    value={formData.username}
                    id="username"
                    placeholder="Enter your username"
                />
                <br />
                <label htmlFor="password">Password:</label>
                <input
                    type="password"
                    onChange={handleChange}
                    value={formData.password}
                    id="password"
                    placeholder="Enter your password"
                />
                <br />
                <button type="button" onClick={handleLogin}>
                    Log In
                </button>

            </form>
        </div>
    );
};

export default AdminLogin;
