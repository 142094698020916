import React from 'react';
import { Link } from 'react-router-dom';
import {
  BsGrid1X2Fill,
  BsFillArchiveFill,
  BsFillGrid3X3GapFill,
  BsPeopleFill,
  BsListCheck,
  BsMenuButtonWideFill,
  BsFillGearFill,
} from 'react-icons/bs';

import logo from '../../assets/logo.svg';


const UserSidebar = () => {

  return (
    <aside id='sidebar'>
      <div className='sidebar-title'>
        <div className='sidebar-brand'>
          <img src={logo} alt="Logo" className="icon_header" />
        </div>
        <span className='icon close_icon'>X</span>
      </div>

      <ul className='sidebar-list'>
        <li className='sidebar-list-item'>
          <Link>
            <BsGrid1X2Fill className='icon' /> Dashboard
          </Link>
        </li>
        <li className='sidebar-list-item'>
          <Link>
            <BsFillArchiveFill className='icon' /> Forum
          </Link>
        </li>
        <li className='sidebar-list-item'>
          <Link>
            <BsFillGrid3X3GapFill className='icon' /> Calendar
          </Link>
        </li>
        <li className='sidebar-list-item'>
          <Link>
            <BsPeopleFill className='icon' /> Tasks
          </Link>
        </li>
        <li className='sidebar-list-item'>
          <Link>
            <BsListCheck className='icon' /> Resources
          </Link>
        </li>
        <li className='sidebar-list-item'>
          <Link>
            <BsMenuButtonWideFill className='icon' /> Payment
          </Link>
        </li>
        <li className='sidebar-list-item'>
          <Link>
            <BsFillGearFill className='icon' /> Settings
          </Link>
        </li>

        <li className='sidebar-list-item'>
          <Link>
            <BsFillGearFill className='icon' /> Logout
          </Link>
        </li>
      </ul>
    </aside>
  );
};

export default UserSidebar;
