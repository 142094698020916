import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import logo from '../assets/logo.svg';
import hagit from './certificate.svg';



const EmailOtp = () => {
  return (
    <div className="container">
      <div className="text-container">
        <img src={logo} alt="hagit" className="logo" />
        <h1 className="hagit-text">Verify with your<br></br>
          <span>Phone</span> Number.</h1>
        <p className='hagit-small-text'>We need your phone number<br></br>
          in other to verify that your certificate</p>
      </div>
      <div className="image-container">
        <img src={hagit} alt="hagit meet" className="image" />
      </div>

      <div className="password-container">
        <form>
        <p>An OTP has been sent to your email address</p>
          <input type="password" placeholder="Enter OTP" />
          <input type="submit" value="Confirm" />
          <p>Having trouble?
           <span><Link to='/welcome'> talk to us.</Link></span></p>
           
        </form>
      </div>
    </div>
  );
};

export default EmailOtp;
