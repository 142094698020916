import React from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import './App.css';
import './/assets/css/Admin.css';
import logo from './assets/logo.svg';
import hagit from './assets/meet-hagit.svg';

import AdminApp from './admin/App';
import Header from './admin/Component/Header';
import SideBar from './admin/Component/Sidebar';
import Content from './admin/Component/Content';

import UserApp from './user/App';
import UserHeader from './user/Component/Header';
import UserSideBar from './user/Component/Sidebar';
import UserContent from './user/Component/Content';

import Access from "./certificate/Access";
import Certificate from "./certificate/App";
import EmailOtp from "./certificate/EmailOtp";
import CertificatePage from "./certificate/CertificatePage";
import CertificateConfirmationPage from "./certificate/CertificateConfirmationPage";






const App = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<MainApp />} />
        <Route path="/admin/*" element={<AdminApp />} />
        <Route path="/admin/*" element={<Header />} />
        <Route path="/admin/*" element={<SideBar />} />
        <Route path="/admin/*" element={<Content />} />

        <Route path="/user/*" element={<UserApp />} />
        <Route path="/user/*" element={<UserHeader />} />
        <Route path='/user/*' element={<UserSideBar />} />
        <Route path='/user/*' element={<UserContent />} />

        <Route path='/certificate/*' element={<Certificate />} />
        <Route path='/certificate/*' element={<Access />} />
        <Route path='/certificate/*' element={<EmailOtp />} />
        <Route path='/certificate/*' element={<CertificatePage />} />
        <Route path='/certificate/*' element={<CertificateConfirmationPage />} />
      </Routes>
    </div>
  );
};


const MainApp = () => {
  return (
    <div className="container">
      <div className="text-container">
        <img src={logo} alt="hagit" className="logo" />
        <h1 className="hagit-text">Sign in with your<br></br>
          <span>Hagit</span> Pin.</h1>
        <p className='hagit-small-text'>If you don’t have an Hagit code<br></br>
          you can <span><Link to='/welcome'>talk to us</Link></span></p>
      </div>
      <div className="image-container">
        <img src={hagit} alt="hagit meet" className="image" />
      </div>

      <div className="password-container">
        <form>
          <input type="password" placeholder="Enter your hagit pin" />
          <input type="submit" value="Sign In" />
          <p>Misplaced your pin
           <span><Link to='/welcome'> retrieve it.</Link></span></p>
           
        </form>
      </div>
    </div>
  );
};

export default App;
