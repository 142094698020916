import React, { useState, useEffect } from 'react';
import { BsFillGrid3X3GapFill, BsPeopleFill } from 'react-icons/bs';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line } from 'recharts';
import { subMonths, format } from 'date-fns';

function Home() {
  const [dashboardData, setDashboardData] = useState(null);
  const [chartFilter, setChartFilter] = useState({ year: new Date().getFullYear(), month: new Date().getMonth() + 1 }); // Initialize with the current year and month

  useEffect(() => {
    // Fetch data from your backend API with the filter value
    fetch(`https://app.hagitalconsulting.com/hagitbackend/dashboard.php?filter=${format(new Date(chartFilter.year, chartFilter.month - 1), 'yyyy-MM-dd')}`)
      .then((response) => response.json())
      .then((data) => setDashboardData(data))
      .catch((error) => console.error('Error fetching dashboard data:', error));
  }, [chartFilter]);

  if (!dashboardData) {
    return <p>Loading...</p>;
  }

  const { totalStudents, totalEnrolled, totalFacilitators, studentsOwing } = dashboardData;

  // Customize data based on the actual numbers
  const dataEnrolled = [
    { name: 'Total Enrolled', enrolled: totalEnrolled },
    // Add more data points as needed
  ];

  const dataOwing = [
    { name: 'Total Owing', owing: studentsOwing },
    // Add more data points as needed
  ];

  const handleFilterChange = (year, month) => {
    setChartFilter({ year, month });
  };

  const yearOptions = Array.from({ length: 5 }, (_, index) => new Date().getFullYear() - index);
  const monthOptions = Array.from({ length: 12 }, (_, index) => index + 1);

  return (
    <main className='main-container'>
      <div className='main-title'>
        <h3>DASHBOARD</h3>
      </div>

      <div className='main-cards'>
        <div className='card'>
          <div className='card-inner'>
            <h3>TOTAL STUDENTS</h3>
            <BsPeopleFill className='card_icon' />
          </div>
          <h1>{totalStudents}</h1>
        </div>
        <div className='card'>
          <div className='card-inner'>
            <h3>TOTAL ENROLLED</h3>
            <BsPeopleFill className='card_icon' />
          </div>
          <h1>{totalEnrolled}</h1>
        </div>
        <div className='card'>
          <div className='card-inner'>
            <h3>FACILITATORS</h3>
            <BsPeopleFill className='card_icon' />
          </div>
          <h1>{totalFacilitators}</h1>
        </div>
        <div className='card'>
          <div className='card-inner'>
            <h3>STUDENTS OWING</h3>
            <BsFillGrid3X3GapFill className='card_icon' />
          </div>
          <h1>{studentsOwing}</h1>
        </div>
      </div>

      <div className='charts'>
         {/* Chart 1: Total Students Enrolled */}
        <div className="chart-container">
          <div className="filter-container">
            <label>Filter Enrolled Students</label>
            <select
              value={chartFilter.year}
              onChange={(e) => handleFilterChange(parseInt(e.target.value, 10), chartFilter.month)}
            >
              {yearOptions.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
            <select
              value={chartFilter.month}
              onChange={(e) => handleFilterChange(chartFilter.year, parseInt(e.target.value, 10))}
            >
              {monthOptions.map((month) => (
                <option key={month} value={month}>
                  {format(new Date(2000, month - 1), 'MMMM')}
                </option>
              ))}
            </select>
          </div>
        <ResponsiveContainer width='100%' height={300}>
          <BarChart
            data={dataEnrolled}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='name' />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey='enrolled' fill='#8884d8' />
          </BarChart>
        </ResponsiveContainer>
        </div>

      {/* Chart 2: Total Students Owing */}
      <div className="chart-container">
          <div className="filter-container">
            <label>Filter Owing Students</label>
            <select
              value={chartFilter.year}
              onChange={(e) => handleFilterChange(parseInt(e.target.value, 10), chartFilter.month)}
            >
              {yearOptions.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
            <select
              value={chartFilter.month}
              onChange={(e) => handleFilterChange(chartFilter.year, parseInt(e.target.value, 10))}
            >
              {monthOptions.map((month) => (
                <option key={month} value={month}>
                  {format(new Date(2000, month - 1), 'MMMM')}
                </option>
              ))}
            </select>
          </div>
        <ResponsiveContainer width='100%' height={300}>
          <LineChart
            data={dataOwing}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='name' />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type='monotone' dataKey='owing' stroke='#82ca9d' activeDot={{ r: 8 }} />
          </LineChart>
        </ResponsiveContainer>
        </div>
      </div>
    </main>
  );
}

export default Home;
