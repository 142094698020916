import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import UserDashboard from './Dashboard';
import UserHeader from './Component/Header';
import UserSideBar from './Component/Sidebar';
import UserContent from './Component/Content';


const UserApp = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<Navigate to="/user/Dashboard" replace />}
      />

      <Route path="/user/Dashboard" element={<UserDashboard />} />
      <Route path="/user/Header" element={<UserHeader />} />
      <Route path='/user/Sidebar' element={<UserSideBar />} />
      <Route path='/Component/Content' element={<UserContent />} />
    </Routes>
  );
};

export default UserApp;
