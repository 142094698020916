import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Access from './Access';
import EmailOtp from "./EmailOtp";
import CertificatePage from "./CertificatePage";
import CertificateConfirmationPage from "./CertificateConfirmationPage";



const Certificate = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<Navigate to="/certificate/Access" replace />}
      />

      <Route path="/Access" element={<Access />} />
      <Route path="/EmailOtp" element={<EmailOtp />} />
      <Route path="/CertificatePage" element={<CertificatePage />} />
      <Route path="/CertificateConfirmationPage" element={<CertificateConfirmationPage />} />
      

    </Routes>
  );
};

export default Certificate;
