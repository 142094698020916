  import React from 'react';
import UserHeader from './Component/Header';
import UserSidebar from './Component/Sidebar';
import UserContent from './Component/Content';
import UserFooter from './Component/Footer';
import '../assets/css/Dashboard.css';

const UserDashboard = () => {
  return (
    <div className="grid-container">
      <UserHeader />
        <UserSidebar />
        <UserContent />
      <UserFooter />
    </div>
  );
};

export default UserDashboard;
