import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BsFilter } from 'react-icons/bs';
import { CSVLink } from 'react-csv';
import * as XLSX from 'xlsx';
import Header from '../Header';
import Sidebar from '../Sidebar';
import Footer from '../Footer';
import '../../../assets/css/Dashboard.css';
import '../../../assets/css/students.css';

const Enrollment = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState('full');
  const [remainingAmount, setRemainingAmount] = useState('');
  const [userSuccessMessages, setUserSuccessMessages] = useState({});
  const [approvalStatus, setApprovalStatus] = useState({});
  const [csvData, setCsvData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    // Initialize user success messages based on data
    const initialUserMessages = data.reduce((acc, user) => {
      acc[user.email] = '';
      return acc;
    }, {});
    setUserSuccessMessages(initialUserMessages);
  }, [data]);

  useEffect(() => {
    // Fetch data from the PHP API endpoint
    fetch('https://app.hagitalconsulting.com/hagitbackend/api.php', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('API Response:', data);
        setData(data);
        setFilteredData(data);
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);

    const filteredResults = data.filter((item) =>
      expectedKeys.some((key) =>
        key === 'courses'
          ? item[key].toLowerCase().includes(term)
          : item[key].toString().toLowerCase().includes(term)
      )
    );

    setFilteredData(filteredResults);
  };

  const expectedKeys = [
    'id',
    'name',
    'email',
    'whatsapp',
    'courses',
    'payment_path',
    'name_on_account',
  ];

  const handleViewDetails = (item) => {
    setSelectedItem(item);
  };

  const handleApprove = async () => {
    if (!selectedItem) {
      console.log('No selected item');
      return;
    }

    setLoading(true);

    const remainingAmountValue =
      paymentStatus === 'partial' ? remainingAmount : 0;

    if (approvalStatus[selectedItem.email]) {
      console.log('User is already approved');
      setLoading(false);
      return;
    }

    try {
      const formData = new FormData();
      formData.append('name', selectedItem.name);
      formData.append('email', selectedItem.email);
      formData.append('whatsapp', selectedItem.whatsapp);
      formData.append('courses', selectedItem.course);
      formData.append('paymentStatus', paymentStatus);
      formData.append('remainingAmount', remainingAmountValue);

      const response = await axios.post(
        'https://app.hagitalconsulting.com/hagitbackend/storeUserData.php',
        formData
      );

      console.log('Data stored and email sent:', response.data);

      setData((prevData) => [...prevData, selectedItem]);

      setFilteredData((prevFilteredData) => [...prevFilteredData, selectedItem]);

      setApprovalStatus((prevStatus) => ({
        ...prevStatus,
        [selectedItem.email]: true,
      }));

      setUserSuccessMessages((prevMessages) => ({
        ...prevMessages,
        [selectedItem.email]: 'Student HagitPin has been sent',
      }));
    } catch (error) {
      console.error('Error storing data and sending email:', error);
    } finally {
      setLoading(false);
      setSelectedItem(null);
    }
  };

  const isUserApproved = approvalStatus[selectedItem?.email];

  const handleReject = () => {
    console.log('Rejected:', selectedItem);
    setSelectedItem(null);
  };

  const generateCsvData = () => {
    const formattedCsvData = filteredData.map((item) => ({
      name: item.name,
      email: item.email,
      whatsapp: item.whatsapp,
      courses: item.courses,
      payment_path: item.payment_path,
      name_on_account: item.name_on_account,
    }));
    setCsvData(formattedCsvData);
  };

  const handleDownload = (format) => {
    if (format === 'csv') {
      generateCsvData();
    } else if (format === 'excel') {
      const ws = XLSX.utils.json_to_sheet(filteredData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'enrollment_data.xlsx');
    } else if (format === 'print') {
      window.print();
    }
  };

  return (
    <div className="grid-container">
      <Header />
      <Sidebar />
      <main className="main-container">
        <h3>Student Enrollment list of those who recently made payment</h3>
        <div className="search-box">
          <BsFilter className="filter-icon" />
          <input
            type="text"
            placeholder="Search by name or phone number"
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
        <div className="download-buttons">
          <CSVLink
            data={csvData}
            filename="enrollment_data.csv"
            onClick={() => generateCsvData()}
            className="download-btn csv-button"
          >
            CSV
          </CSVLink>
          <button
            onClick={() => handleDownload('excel')}
            className="download-btn"
          >
            Excel
          </button>
          <button
            onClick={() => handleDownload('print')}
            className="download-btn print-btn"
          >
            Print
          </button>
        </div>

        {/* Printable Table */}
        <div className="printable-table">
          <table className="data-table">
            <thead>
              <tr>
                {expectedKeys.map((key, index) => (
                  <th key={index}>
                    {key === 'id' && 'S/N'}
                    {key === 'name' && 'Full name'}
                    {key === 'email' && 'Email Address'}
                    {key === 'whatsapp' && 'WhatsApp Number'}
                    {key === 'courses' && 'Preferred Course'}
                    {key === 'payment_path' && 'Proof of Payment'}
                    {key === 'name_on_account' && 'Name on Account'}
                  </th>
                ))}
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredData
                .slice(
                  (currentPage - 1) * itemsPerPage,
                  currentPage * itemsPerPage
                )
                .map((item, index) => (
                  <tr key={index}>
                    {expectedKeys.map((key, subIndex) => (
                      <td key={subIndex}>
                        {key === 'payment_path' ? (
                          <img
                            src={
                              'https://app.hagitalconsulting.com/hagitbackend/payment-proof' +
                              item[key]
                            }
                            alt="Payment Proof"
                            style={{
                              maxWidth: '50px',
                              height: '50px',
                              cursor: 'pointer',
                            }}
                            onClick={() => handleViewDetails(item)}
                          />
                        ) : key === 'courses' ? (
                          <span className="details-value">{item[key]}</span>
                        ) : (
                          item[key]
                        )}
                      </td>
                    ))}
                    <td>
                      <button
                        className="view-btn"
                        onClick={() => handleViewDetails(item)}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>

          {/* Pagination */}
          <div className="pagination">
            {Array.from({
              length: Math.ceil(filteredData.length / itemsPerPage),
            }).map((_, index) => (
              <button key={index} onClick={() => paginate(index + 1)}>
                {index + 1}
              </button>
            ))}
          </div>
        </div>

        {selectedItem && (
          <div className="popup">
            <div className="popup-content">
              <span
                className="close-popup"
                onClick={() => setSelectedItem(null)}
              >
                &times;
              </span>
              <h2 className="details-heading">Details</h2>
              {expectedKeys.map((key, index) => (
                <div key={index} className="details-item">
                  <strong className="details-label">
                    {key === 'id' && 'S/N'}
                    {key === 'name' && 'Full Name'}
                    {key === 'email' && 'Email Address'}
                    {key === 'whatsapp' && 'WhatsApp Number'}
                    {key === 'courses' && 'Preferred Course'}
                    {key === 'payment_path' && 'Proof of Payment'}
                    {key === 'name_on_account' && 'Name on Account'}
                  </strong>{' '}
                  {key === 'payment_path' ? (
                    <img
                      src={selectedItem[key]}
                      alt="Payment"
                      className="payment-image"
                    />
                  ) : (
                    <span className="details-value">{selectedItem[key]}</span>
                  )}
                </div>
              ))}
              <div className="payment-status-form">
                <label
                  className="payment-status-label"
                  htmlFor="paymentStatus"
                >
                  Payment Status:
                </label>
                <select
                  id="paymentStatus"
                  name="paymentStatus"
                  onChange={(e) => setPaymentStatus(e.target.value)}
                >
                  <option value="full">Full Payment</option>
                  <option value="partial">Partial Payment</option>
                </select>
                {paymentStatus === 'partial' && (
                  <div>
                    <label htmlFor="remainingAmount">Remaining Amount:</label>
                    <input
                      type="text"
                      id="remainingAmount"
                      name="remainingAmount"
                      value={remainingAmount}
                      onChange={(e) => setRemainingAmount(e.target.value)}
                      placeholder="Enter remaining amount"
                    />
                  </div>
                )}
              </div>
              <div className="action-buttons">
                <button
                  id="approveBtn"
                  className="approve-btn"
                  onClick={handleApprove}
                  disabled={loading || isUserApproved}
                >
                  {loading ? 'Loading...' : 'Approve'}
                </button>
                <span className="button-space"></span>
                <button
                  id="rejectBtn"
                  className="reject-btn"
                  onClick={handleReject}
                  disabled={loading}
                >
                  {loading ? 'Loading...' : 'Reject'}
                </button>
              </div>
              {userSuccessMessages[selectedItem.email] && (
                <div className="success-message">
                  {userSuccessMessages[selectedItem.email]}
                </div>
              )}
            </div>
          </div>
        )}
      </main>
      <Footer />
    </div>
  );
};

export default Enrollment;
